<template>
  <v-dialog :value="isFinite(value)" transition="dialog-top-transition" max-width="1000" persistent scrollable>
    <v-card>
      <v-window v-model="openWindow">
        <v-window-item>

          <v-card-actions>
            <v-spacer></v-spacer>
            <k-btn color="secondary" outlined @click="$emit('input', false)">{{ $t('actions.cancel') }}</k-btn>
            <k-btn color="secondary" @click="saveAddonSelection">{{ $t('actions.save') }}</k-btn>
          </v-card-actions>
          <v-card-title class="pt-0 text-h3 font-weight-bold text-uppercase">
            <span class="primary--text pr-2">{{ item.name }}</span>
            /
            <span class="px-2">{{ $t('shoppingCart.addons.title') }}</span>
            <span v-if="item.minRequiredAddons > 0" class="pl-2 text-body-1">
              ({{
                $t('shoppingCart.addons.dialog.minRequiredAddons', [this.selectedWithQuantities.length, item.minRequiredAddons])
              }})
            </span>
          </v-card-title>
          <v-card-text class="px-0">

            <v-list three-line>
              <v-list-item-group v-model="selected" active-class="primary--text" multiple>
                <template v-for="(addon, index) in list">
                  <v-list-item class="px-2" :key="addon.name">
                    <template v-slot:default="{ active }">
                      <v-list-item-avatar tile width="100px" height="100px" class="my-0 mx-2">
                        <v-img :src="`${addon.image}&type=thumbnail`"
                               :lazy-src="`${addon.image}&type=lazy`"
                               height="100px"
                               max-width="100px"
                               contain>
                          <v-btn color="secondary"
                                 @click.stop="enlargeImage(addon.image)"
                                 elevation="1"
                                 absolute
                                 fab
                                 tile
                                 style="right: 0; width: 24px; height: 24px">
                            <v-icon small>$enlarge</v-icon>
                          </v-btn>
                        </v-img>
                      </v-list-item-avatar>
                      <v-list-item-content class="py-0">
                        <v-row align="center" class="fill-height" no-gutters>
                          <v-col cols="5">
                            <div class="text-body-1 pb-1">{{ addon.name }}</div>
                            <div class="text-body-2 pb-2">{{ addon.description }}</div>
                            <div class="text-caption pb-1">
                          <span class="grey--text text--darken-3 font-italic">
                            {{ $t('shoppingCart.headers.itemCode') }}:
                          </span>
                              <span class="pl-2">{{ addon.sku }}</span>
                            </div>

                          </v-col>
                          <v-col cols="3" class="text-center">
                            <k-btn color="secondary" outlined v-if="active">{{ $t('actions.remove') }}</k-btn>
                            <k-btn color="secondary" v-else>{{ $t('actions.add') }}</k-btn>
                          </v-col>
                          <v-col class="px-2">
                            <KFigureField step="1"
                                          @click.native.stop
                                          class="pt-5"
                                          v-model="quantities[addon.id]"
                                          :label="$t('shoppingCart.addons.dialog.amount')"
                                          hide-details
                                          dense/>
                          </v-col>
                          <v-col class="px-2">
                            <div class="text-caption grey--text text--darken-3 font-italic">
                              {{ $t('shoppingCart.addons.dialog.price') }}:
                            </div>
                            <div class="text-right d-flex align-center">
                              <LocalePrice :price="addon.localPrice" badge-class="mr-2"/>
                              {{ $n(addon.price, 'currency') }}
                            </div>
                          </v-col>
                          <v-col class="px-2">
                            <div class="text-caption grey--text text--darken-3 font-italic">
                              {{ $t('shoppingCart.addons.dialog.totalPrice') }}:
                            </div>
                            <div class="text-right d-flex align-center">
                              <LocalePrice :price="addon.localPrice * quantities[addon.id]" badge-class="mr-2"/>
                              {{ $n(addon.price * quantities[addon.id], 'currency') }}
                            </div>
                          </v-col>
                        </v-row>
                      </v-list-item-content>
                    </template>
                  </v-list-item>

                  <VDivider v-if="index < list.length - 1" :key="`list-divider[${index}]`"/>
                </template>
              </v-list-item-group>
            </v-list>
          </v-card-text>

        </v-window-item>
        <v-window-item eager>
          <div>
            <v-img ref="enlargedImg"
                   :src="enlargedImage"
                   @load="handleImgLoad"
                   @error="handleImgError"
                   contain
                   height="600px"
                   width="100%">
              <v-btn class="ml-5" style="top: 50%;" icon absolute large @click="closeEnlargedImage">
                <v-icon large>$prev</v-icon>
              </v-btn>
            </v-img>
          </div>
        </v-window-item>
      </v-window>
    </v-card>
  </v-dialog>
</template>

<script>
import { EventBus } from '@/application/EventBus.js';
import KFigureField from '@/components/crud/fields/KFigureField.vue';
import KBtn from '@/components/KButton.vue';
import { saveAddonSelection } from '@/modules/shoppingCart/api';
import LocalePrice from '@/components/LocalePrice.vue';

export default {
  name: 'addonsForm',
  components: {
    LocalePrice,
    KFigureField,
    KBtn,
  },
  data() {
    return {
      selected: [],
      openWindow: 0,
      enlargedImage: null,
      quantities: {},
    };
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    value: {
      type: Number,
    },
    list: {
      type: Array,
      required: true,
    },
  },
  computed: {
    selectedAddonIds() {
      return this.selected.map(listIndex => this.list[listIndex]?.id);
    },
    selectedWithQuantities() {
      return Object.entries(this.quantities)
          .filter(([id]) => this.selectedAddonIds.includes(parseInt(id)))
          .map(([id, quantity]) => ({
            id,
            quantity: quantity > 0 ? quantity : 1,
          }));
    },
  },
  created() {
    this.selected = this.item.addOns.products.map(addon => this.list.findIndex(({ id }) => id === addon.id));
    this.quantities = Object.fromEntries(this.list.map(({
      id,
    }) => {
      const addOn = this.item.addOns.products.find(addOn => addOn.id === id);
      const quantity = addOn?.quantity || 0;
      return [id, quantity > 0 ? quantity : 1];
    }));
  },
  methods: {
    async saveAddonSelection() {
      try {
        const addOns = this.selectedWithQuantities;
        await saveAddonSelection(this.item.id, { addOns });
        this.$emit('reload-line', this.item.id);
        this.$emit('input', false);
      } catch (e) {
        EventBus.$emit('snackbar', {
          color: 'error',
          text: this.$t('errors.defaultErrorWrapper', [this.$t('errors.saveSelectedAddons')]),
        });
      }
    },
    enlargeImage(image) {
      this.enlargedImage = `${image}&type=medium`;
    },
    async closeEnlargedImage() {
      this.openWindow = 0;
      await this.$nextTick();
      this.enlargedImage = null;
    },
    async handleImgLoad() {
      this.openWindow = 1;
    },
    handleImgError() {
      EventBus.$emit('snackbar', {
        color: 'error',
        text: this.$t('errors.defaultErrorWrapper', [this.$t('errors.enlargeImage')]),
      });
    },
  },
};
</script>

<style scoped>
.v-window {
  overflow-y: auto;
}
</style>
